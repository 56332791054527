import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { faAnglesDown, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAshCharacter } from '../../modules/common/model/graphql-types';
import lodash from 'lodash';
import { Accordion, Button, Card } from 'react-bootstrap';
import { AshCharacter } from '../../modules/ash/components/ash-character';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

interface IAshCharacterNodes {
  nodes: IAshCharacter[];
}

interface IAshCharacterEntry {
  allCharacters: IAshCharacterNodes;
}

interface IProps {
  data: IAshCharacterEntry;
}

const AshTierPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '1✦', value: '1' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Bulwark',
            tooltip: 'Bulwark',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_bulwark.webp"
                width={24}
                alt="Bulwark"
              />
            )
          },
          {
            value: 'Ranger',
            tooltip: 'Ranger',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_ranger.webp"
                width={24}
                alt="Ranger"
              />
            )
          },
          {
            value: 'Skirmisher',
            tooltip: 'Skirmisher',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_skirmisher.webp"
                width={24}
                alt="Skirmisher"
              />
            )
          },
          {
            value: 'Striker',
            tooltip: 'Striker',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_striker.webp"
                width={24}
                alt="Striker"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_support.webp"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Tactician',
            tooltip: 'Tactician',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_tactician.webp"
                width={24}
                alt="Tactician"
              />
            )
          },
          {
            value: 'Vanguard',
            tooltip: 'Vanguard',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_vanguard.webp"
                width={24}
                alt="Vanguard"
              />
            )
          }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Corrosion',
            tooltip: 'Corrosion',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_corrosion.webp"
                width={24}
                alt="Corrosion"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_fire.webp"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_ice.webp"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Lightning',
            tooltip: 'Lightning',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_lightning.webp"
                width={24}
                alt="Lightning"
              />
            )
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_physical.webp"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_water.webp"
                width={24}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_wind.webp"
                width={24}
                alt="Wind"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'cn') {
      setSortOption('cn');
    }

    if (sortOption === 'global') {
      setSortOption('global');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'global', label: 'Global' },
    { value: 'cn', label: 'CN' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout className={'generic-page ash-tier'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ash/categories/category_tier.webp"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Tier List</h1>
          <h2>
            Ash Echoes tier list that rates all characters from both Global and
            CN versions of the game based on their performance.
          </h2>
          <p>
            Last updated: <strong>14/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Tier List" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>
                Ash Echoes is a game where Team Building and Strategy are the
                main focus, and this should be taken into consideration over
                individual character “power”.
              </strong>{' '}
              As such, please take the ratings listed here merely as a reference
              and not as a hard rule. Remember to pay attention to how each
              character and team functions for a better experience.
            </p>
            <p>
              In this Tier List, characters are rated based on how they perform
              within their designated role. This format was chosen in order to
              facilitate team building. Consequently, sentences like “Freda is
              stronger than Hassel” make no sense in this context, as those
              characters fulfill completely different roles and purposes.
            </p>
            <h6>Categories</h6>
            <ul>
              <li>
                <strong className="dps">Damage Dealer</strong>: Characters whose
                function is focused on being the main source of Damage for the
                team.
              </li>
              <li>
                <strong className="specialist">Specialist</strong>: Echomancers
                of this type can serve many different roles in a team. Their
                main utility can come from Barrier PEN, Control, Interception,
                creating Elemental Zones, and other battlefield-altering
                techniques. They can also trigger Elemental Reactions to help
                players achieve victory.
              </li>
              <li>
                <strong className="buffer">Buffer/Debuffer</strong>: Echomancers
                of this type can grant buffs to allied DPS characters or lower
                enemy RES/increase enemy DMG taken to greatly enhance team DPS
                for a short time.
              </li>
              <li>
                <strong className="survival">Survival</strong>: Healers and
                Tanks, their main role is to make sure that the team survives.
                Some of them can also offer a bit of Utility or Damage.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Criteria</Accordion.Header>
          <Accordion.Body>
            <h6>Criteria</h6>
            <p>Echomancers are evaluated as such:</p>
            <ul>
              <li>6✦ at 0 dupes, 5✦ at 3 dupes, 4✦ and below at max dupes.</li>
              <li>
                It’s taken into consideration: Raw power, versatility (teams and
                builds), utility (how unique or essential their kits are), cost.
              </li>
              <li>Top Picks</li>
              <ul>
                <li>
                  <strong>S Tier</strong>: The absolute best at their category,
                  while also offering extra benefits.
                </li>
                <li>
                  <strong>A Tier</strong>: On par with the characters from S
                  Tier, but lack the extra benefits that make the S Tier unique.
                </li>
              </ul>
              <li>Good Picks</li>
              <ul>
                <li>
                  <strong>B Tier</strong>: On par with the characters from A
                  Tier, but have some sort of drawback, such as needing extra
                  investment or a specific environment.
                </li>
                <li>
                  <strong>C Tier</strong>: Not bad, but definitely need
                  investment or specific circumstances to shine and reach the
                  same level of B Tier or above.
                </li>
              </ul>
              <li>Niche Picks</li>
              <ul>
                <li>
                  <strong>D Tier</strong>: Characters who work on very specific
                  (niche) circumstances or who rarely play their role,
                  functioning as a placeholder or substitute for characters who
                  are from tiers above.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Changelog</Accordion.Header>
          <Accordion.Body>
            <h6>28.10.2024</h6>
            <p>Initial release of the tier list.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="banner ash-promo">
        <h4>Play & Reroll Ash Echoes on PC</h4>
        <OutboundLink href="https://leap.ldplayer.gg/T4ndFUlZ0" target="_blank">
          <Button variant="primary">Reroll now on LDPlayer</Button>
        </OutboundLink>
      </div>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'global' && 'Global'}
            {sortOption === 'cn' && 'CN'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'global' && 'Global'}
            {sortOption === 'cn' && 'CN'}
          </strong>{' '}
          tier list. It shows{' '}
          {sortOption === 'global' &&
            'how the character performs within their role in all PVE content available in the Global server.'}
          {sortOption === 'cn' &&
            'how the character performs within their role in all PVE content available in the CN server.'}{' '}
          Use the switcher below to view a different tier list.
        </p>
      </div>
      <div className="tier-list-switcher">
        <div
          className={`option global ${sortOption === 'global' && 'selected'}`}
          onClick={() => setSortOption('global')}
        >
          <p>Global Server</p>
        </div>
        <div
          className={`option cn ${sortOption === 'cn' && 'selected'}`}
          onClick={() => setSortOption('cn')}
        >
          <p>CN Server</p>
        </div>
      </div>
      <div className="employees-filter-bar ash">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-ash">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header specialist">Specialist</div>
            <div className="custom-header buffer">Buffer/Debuffer</div>
            <div className="custom-header survival">Survival</div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header top">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Top Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s first">
          <div className="tier-rating s">
            <span>S</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 9)
                .filter((emp) => emp.tierCategory === 'DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile specialist">Specialist</div>
            <div className="employees-container specialist">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 9)
                .filter((emp) => emp.tierCategory === 'Specialist')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile buffer">Buffer/Debuffer</div>
            <div className="employees-container buffer">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 9)
                .filter((emp) => emp.tierCategory === 'Buffer')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 9)
                .filter((emp) => emp.tierCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>A</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 8)
                .filter((emp) => emp.tierCategory === 'DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile specialist">Specialist</div>
            <div className="employees-container specialist">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 8)
                .filter((emp) => emp.tierCategory === 'Specialist')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile buffer">Buffer/Debuffer</div>
            <div className="employees-container buffer">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 8)
                .filter((emp) => emp.tierCategory === 'Buffer')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 8)
                .filter((emp) => emp.tierCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header alt">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Good Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>B</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 7)
                .filter((emp) => emp.tierCategory === 'DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile specialist">Specialist</div>
            <div className="employees-container specialist">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 7)
                .filter((emp) => emp.tierCategory === 'Specialist')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile buffer">Buffer/Debuffer</div>
            <div className="employees-container buffer">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 7)
                .filter((emp) => emp.tierCategory === 'Buffer')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 7)
                .filter((emp) => emp.tierCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>C</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 6)
                .filter((emp) => emp.tierCategory === 'DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile specialist">Specialist</div>
            <div className="employees-container specialist">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 6)
                .filter((emp) => emp.tierCategory === 'Specialist')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile buffer">Buffer/Debuffer</div>
            <div className="employees-container buffer">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 6)
                .filter((emp) => emp.tierCategory === 'Buffer')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 6)
                .filter((emp) => emp.tierCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-d">
          <div className="tier-rating d">
            <span>D</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 5)
                .filter((emp) => emp.tierCategory === 'DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile specialist">Specialist</div>
            <div className="employees-container specialist">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 5)
                .filter((emp) => emp.tierCategory === 'Specialist')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile buffer">Buffer/Debuffer</div>
            <div className="employees-container buffer">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 5)
                .filter((emp) => emp.tierCategory === 'Buffer')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === 5)
                .filter((emp) => emp.tierCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <AshCharacter
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default AshTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Ash Echoes | Prydwen Institute"
    description="Ash Echoes tier list that rates all characters from both Global and CN versions of the game based on their performance."
    game="ash"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAshCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        element
        tierComment {
          raw
        }
        tierCategory
        ratings {
          cn
          global
        }
      }
    }
  }
`;
